import {twMerge} from 'tailwind-merge';

type Props = {
  className?: string;
};

const Separator = ({className}: Props) => {
  return (
    <div
      className={twMerge(
        'h-[2px] rounded-full w-[80%] bg-(--subtle-color) mx-(--page-padding) self-center my-10 max-[800px]:my-4 max-[500px]:my-2',
        className
      )}
    />
  );
};

export default Separator;
