import Link from 'next/link';
import {instagramLine, smartphoneLine, twitterXLine} from 'src/utils/icons';
import Separator from './common/Separator';
import RemixIcon from './RemixIcon';

type Props = {
  hideArticles?: boolean;
};

const Footer = ({hideArticles}: Props) => {
  return (
    <>
      {hideArticles ? null : (
        <section className="max-w-[900px] px-(--page-padding) text-(--gray-text) mx-auto flex flex-col items-center gap-4 pt-8 [content-visibility:auto]">
          <span className="text-(--text-color)">Popular on Humbo right now</span>

          <div className="flex flex-wrap justify-center gap-x-4 gap-y-2 text-center">
            <Link prefetch={false} href="/top100">
              The best places to visit in the world
            </Link>
            <Link prefetch={false} href="/humbo/lists/the-30-best-food-cities-in-the-world">
              The 30 best food cities in the world
            </Link>
            <Link prefetch={false} href="/humbo/lists/some-of-the-most-beautiful-national-parks-in-the-world">
              The most beautiful national parks in the world
            </Link>
            <Link prefetch={false} href="/humbo/lists/best-places-for-women-to-travel-alone">
              The best places for women to travel alone
            </Link>
            <Link prefetch={false} href="/humbo/lists/the-20-happiest-cities-in-the-world">
              The 20 happiest cities in the world
            </Link>
            <Link prefetch={false} href="/humbo/lists/the-best-disc-golf-destinations-in-the-world">
              The best disc golf destinations in the world
            </Link>
            <Link prefetch={false} href="/humbo/lists/the-warmest-places-in-europe-during-winter">
              The warmest places in Europe during winter
            </Link>
            <Link prefetch={false} href="/humbo/lists/the-10-most-expensive-cities-in-the-world">
              The 10 most expensive cities in the world
            </Link>
          </div>

          <Separator />
        </section>
      )}

      <section className="flex justify-center flex-wrap pb-10 pt-2 text-(--gray-text) gap-x-6 mx-4 *:py-2 [&>a]:font-medium [&>a]:hover:text-(--purple) [&>a]:transition-colors">
        <span>Humbo™</span>

        <Link href="/about" prefetch={false}>
          About
        </Link>

        <Link href="/visited" prefetch={false}>
          Visited
        </Link>

        <Link href="/travel-bucket-list" prefetch={false}>
          Bucket list
        </Link>

        <Link href="/quiz" prefetch={false}>
          Quizzes
        </Link>

        <Link href="/sustainable-travel" prefetch={false}>
          Sustainable travel
        </Link>

        <Link href="/privacy-policy" prefetch={false}>
          Privacy
        </Link>

        <Link href="/terms" prefetch={false}>
          Terms
        </Link>

        <div className="flex flex-wrap gap-6">
          <a
            href="https://apps.apple.com/us/app/humbo-travel-bucket-list/id1669920390"
            target="_blank"
            rel="noreferrer me external"
            aria-label="Humbo iOS app"
          >
            <RemixIcon icon={smartphoneLine} size={20} color="var(--gray-text)" />
          </a>

          <a
            href="https://www.instagram.com/humbotravel/"
            target="_blank"
            rel="noreferrer me external"
            aria-label="Humbo Instagram"
          >
            <RemixIcon icon={instagramLine} size={20} color="var(--gray-text)" />
          </a>

          <a
            href="https://twitter.com/humbotravel"
            target="_blank"
            rel="noreferrer me external"
            aria-label="Humbo Twitter"
          >
            <RemixIcon icon={twitterXLine} size={18} color="var(--gray-text)" className="mt-0.5" />
          </a>
        </div>
      </section>
    </>
  );
};

export default Footer;
