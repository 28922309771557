'use client';

import {useState} from 'react';
import {useRouter} from 'next/navigation';
import AuthSections from 'src/components/auth/AuthSections';
import Footer from 'src/components/Footer';

type Props = {
  initialView: 'login' | 'join';
};

const AuthPage = ({initialView}: Props) => {
  const router = useRouter();

  const [view, setView] = useState<'login' | 'join' | 'authCode' | 'welcome'>(initialView);

  const onAuthenticated = () => {
    setView('welcome');
    router.refresh();

    setTimeout(() => {
      router.push('/');
    }, 1500);
  };

  return (
    <div className="flex flex-col items-center h-[calc(100vh-80px)] justify-between px-4">
      <div />

      <div className="btn-border bg-(--button-bg) rounded-3xl p-8 max-[600px]:p-5 w-full max-w-[480px] mt-8 shadow-[0_0_0_1px_rgb(23_10_37_/_0.07),0_3px_5px_rgb(23_10_37_/_0.02),0_3px_16px_rgb(23_10_37_/_0.04),0_20px_50px_rgb(23_10_37_/_0.07)]">
        <AuthSections initialView={initialView} view={view} setView={setView} onAuthenticated={onAuthenticated} />
      </div>

      <Footer />
    </div>
  );
};

export default AuthPage;
